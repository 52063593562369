
















import Vue from 'vue';
import AppIcon from '@/components/AppIcon.vue';

export default Vue.extend({
  name: 'DashboardLink',
  components: {
    AppIcon,
  },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    disabled: Boolean,
  },
});
