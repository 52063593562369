






import Vue from 'vue';

export default Vue.extend({
  name: 'DashboardLayout',
  inheritAttrs: false,
});
